import React from 'react';

import etics1 from "../images/etics24/etics1.jpg";
import etics2 from "../images/etics24/etics2.jpg";
import etics3 from "../images/etics24/zestaw_gazdzetow.jpg";

import eticsanim from "../video/etics.mp4";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const ETICS = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Visual identification for ETICS conference</p>  
                </div>

                <div>
          
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            <video width="100%" controls autoPlay loop muted>
                                <source src={eticsanim}></source>
                            </video> 
                        </p>
                        <p>
                            The sun is the primary motif of each annual ETICS conference on thermal insulation systems 
                            in buildings. This symbol
                            provides a wide range of possibilities for manipulating the form.
                        </p>
                        <p>
                            The shape of the sun's rays referred to the entrance metal structures of the building
                            where the conference took part.
                        </p>
                        <p>
                            We decided on a calm visual identification that is a natural complement to the 
                            conference area, taking care of its visibility to be easily recognizable in the large
                            and original spaces of the Żnin Sugar Factory.
                        </p>
                        <p>
                            We were responsabile for the design of the key visual 
                            and prints design/production: <br />
                            <ul>
                                <li>sculpture of the conference logo for the stage,</li>
                                <li>signposts,</li>
                                <li>rollups,</li>
                                <li>winders,</li>
                                <li>advertising walls,</li>
                                <li>glass door stickers,</li>
                                <li>totems,</li>
                                <li>identifiers,</li> 
                                <li>notebooks,</li>
                                <li>cotton bag,</li>
                            </ul>
                            as well as graphics for social media, newsletters and electronic versions of programmes.
                        </p>
                        <p>
                            <img src={etics1} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            <img src={etics2} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            <img src={etics3} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            Our studio:
                            <ul>
                                <li>supports local printing houses,</li>
                                <li>makes sure that the printing is on certified papers.</li>
                            </ul>
                        </p>
                        <p>
                            Client: <a href='http://www.systemyocieplen.pl/' target='_blank'>Stowarzyszenie na Rzecz Systemów Ociepleń</a>
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        )

    }

export default ETICS