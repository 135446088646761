import React from "react";
import Fb from './images/icons/f_logo_RGB-White_58.png';
import Be from './images/icons/behance.png';
import In from './images/icons/glyph-logo_May2016.png';

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";

import Footer from './components/Footer/Footer';


const Contact = () => {

        return (
         <div>

            <ScrollToTopOnMount />
            <ScrollToTop smooth />

            <div className="beginning"> 
                <p className="title">Contact</p>
            </div>
    
            <div className="infoframe">
                <div className="infotext">
                    <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        Do not hesitate to contact us, we are happy to discuss cooperation possibilities. An estimate of the job quote is always free.<br/>
                        Follow us on social media for the news, events, offers, or simply for the fresh pictures.
                    </p>
                    <div className="icons"><p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        
                        <u>Get social at:</u>&nbsp;&nbsp;&nbsp;
                     
                        <a href="https://www.facebook.com/pg/rndteam" target="_blank" rel="noopener noreferrer"><img src={Fb} alt="link to Facebook"></img></a>&nbsp;&nbsp;&nbsp;                      
                        <a href="https://www.instagram.com/rnd.team.studio" target="_blank" rel="noopener noreferrer"><img src={In} alt="link to Instagram"></img></a>&nbsp;&nbsp;&nbsp;   
                        <a href="https://www.behance.net/rndteam" target="_blank" rel="noopener noreferrer"><img src={Be} alt="link to Behance"></img></a>&nbsp;&nbsp;&nbsp;
                        
                    </p></div>
                    <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <u>Email us:</u><br/>
                        <a href="mailto:contact@rnd.team">contact@rnd.team</a>
                    </p>

                    <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <u>Call us:</u><br/>
                        Zoom, Skype or any other VoIP is just fine. Email us, we'll arrange the meeting.
                    </p>

                    <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <u>In person:</u><br/>
                        Meeting in the real world is possible as well. 
                        Let us know, we will arrange the appointment either
                        <p>
                        in Bielsko-Biała, Poland
                        </p>
                        <p>
                        or in Isola del Gran Sasso, TE, Italy.
                        </p>
                    </p>

                    <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        We speak Polish, English, and Italian.
                    </p>
                </div>
            </div> 

            <Footer />
         </div>
        )
}

export default Contact;