import React from 'react';
import {Link} from 'react-router-dom';
import './SideDrawer.css';

class sideDrawer extends React.Component {
 
    render() {

        let drawerClasses = 'side-drawer';
        if (this.props.show) {
            drawerClasses = 'side-drawer open';
        }
    
        return (
            <nav 
                className={drawerClasses}> 
                <ul>
                    <Link
                    to="/plotoptix"
                    onClick={() => this.props.drawerClickHandler()}
                    >
                    <p>PlotOptiX</p>
                    </Link>  

                    <Link
                    to="/work"
                    onClick={() => this.props.drawerClickHandler()}
                    >
                    <p>WORK</p>
                    </Link>  

                    <Link 
                    to="/about"
                    onClick={() => this.props.drawerClickHandler()}
                    >
                    <p>ABOUT</p>
                    </Link>
                
                    <Link 
                    to="/contact"
                    onClick={() => this.props.drawerClickHandler()}
                    >
                    <p>CONTACT</p>
                    </Link>

                </ul>
            </nav>
        );
    }
}

export default sideDrawer;